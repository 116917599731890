import React, { useState, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
// MUI DatePicker
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment-timezone";
import Grid from "@mui/material/Grid2";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import { createBudgetItem } from "./BudgetItemSlice";

export default function AddRecordDialog(props) {
  const isOpen = props.isOpen;
  const dispatch = useDispatch();
  const [start, setStart] = useState(moment().startOf('d'));

  // Form
  const [formInput, dispatchFormInput] = useReducer(
    (state, action) => ({ ...state, ...action.payload }),
    {
      itemName: "",
      amount: "",
      category: "",
      date: start.format('YYYY/MM/DD')
    }
  );

  const handleFormInput = (evt) => {
    const { name, value } = evt.target;
    dispatchFormInput({
      type: "patch",
      payload: {
        [name]: value,
      },
    });
  };

  const handleDateChange = (newValue) => {
    setStart(newValue);
    dispatchFormInput({
      type: "patch",
      payload: {
        date: newValue.format('YYYY/MM/DD'),
      },
    });
  };

  return (
    <Dialog
      onClose={props.onClose}
      open={isOpen}
    >
      <DialogTitle>New Record</DialogTitle>
      <DialogContent>
        <FormGroup sx={{ mt: 2, mb: 5 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Date"
              value={start}
              minDate={moment()}
              onChange={handleDateChange}
              format="YYYY/MM/DD"
            />
          </LocalizationProvider>
        </FormGroup>

        <Grid sx={{ mb: 5 }}>
          <TextField
            id="category"
            name="category"
            label="Category"
            variant="outlined"
            fullWidth
            onChange={handleFormInput}
          />
        </Grid>

        <Grid sx={{ mb: 5 }}>
          <TextField
            id="itemName"
            name="itemName"
            label="Item Name"
            variant="outlined"
            fullWidth
            onChange={handleFormInput}
          />
        </Grid>

        <Grid sx={{ mb: 5 }}>
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="amount"
            name="amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Amount"
            fullWidth
            onChange={handleFormInput}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          onClick={async () => {
            await dispatch(
              createBudgetItem({
                ...formInput,
              })
            );
            props.onClose();
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}