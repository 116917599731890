import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function BasicCard({ row }) {
  return (
    <>
      {row.map((item) => (
        <Card sx={{ width: '100%', mb: 2 }} key={item.id}>
          <CardContent>
            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
              {item.category}
            </Typography>
            <Typography variant="h5" component="div">
              {item.itemName}
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 1.5 }}>
              Amount: {item.amount}
            </Typography>
            <Typography variant="body2">
              Date: {item.date}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions>
        </Card>
      ))}
    </>
  );
}

export default BasicCard;