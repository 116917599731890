

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CategoryIcon from '@mui/icons-material/Category';
import BarChartIcon from '@mui/icons-material/BarChart';
import DescriptionIcon from '@mui/icons-material/Description';
import LayersIcon from '@mui/icons-material/Layers';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { Routes, Route, Link  } from "react-router-dom";
import ExpenseTable from '../../../pages/ExpenseTable';

const NAVIGATION = [
  {
    kind: 'header',
    title: 'Main items',
  },
  {
    segment: 'dashboard',
    title: 'Dashboard',
    icon: <DashboardIcon />,
    link: '/dashboard',
  },
  {
    segment: 'expenses',
    title: 'Expenses',
    icon: <ShoppingCartIcon />,
    link: '/expenses',
  },
  {
    segment: 'categories',
    title: 'Categories',
    icon: <CategoryIcon />,
    link: '/categories',
  }
];

function NavBar(props) {

  return (

      <AppProvider navigation={NAVIGATION}>
        <DashboardLayout>
          <Routes>
            <Route path="/dashboard" element={<div>Dashboard Content</div>} />
            <Route path="/expenses" element={<ExpenseTable />} />
            {/* <Route path="/categories" element={<CategoryTable />} /> */}
          </Routes>
        </DashboardLayout>
      </AppProvider>

  );
}

export default NavBar;