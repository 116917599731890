import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import NavBar from "../src/component/base/NavBar/NavBar";
import { Routes, Route } from "react-router-dom";
// import PageRouter from "./pages/PageRouters";
import HomePage from "./pages/ExpenseTable";
import NotificationSnackbar from "./component/base/NotificationSnackbar/NotificationSnackbar";
import Chart from "./pages/Chart";
import CssBaseline from "@mui/material/CssBaseline";
import ExpenseTable from "./pages/ExpenseTable";


function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch("/")
      .then((res) => res.json())
      .then((data) => setData(data.message));
  }, []);

  return (
    <>
      <CssBaseline />
      <NavBar />
    </ >
  );
}

export default App;
